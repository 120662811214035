export enum ChainId {
  MAINNET = 1101,
  TESTNET = 59140,
}

export const NETWORK_URLS: {
  [chainId in ChainId]: string
} = {
  [ChainId.MAINNET]: `https://zkevm-rpc.com`,
  [ChainId.TESTNET]: `https://rpc.goerli.linea.build`,
}
